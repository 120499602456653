import React, {useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth-service";
import { Dropdown } from "react-bootstrap";
import '../../assets/styles/notification_styles.css';
import NotificationService from "../services/notification-service";
import MissionService from "../services/mission-service";

function Navbar() {
  const navigate = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);//notification unread counts
  const [disabledButtons, setDisabledButtons] = useState([]);//join and terminate buttons
  
  const currentUser = AuthService.getCurrentUser();

const isButtonDisabled = (buttonId) => {
    return disabledButtons.includes(buttonId);
  };
  useEffect(() => {
      let isMounted = true;
        const fetchNotifications = async () => {
            
        if (currentUser !== null && currentUser.userId !== null) {
          try {
            const notificationsData = await NotificationService.getAllNotifications(currentUser.userId);
            // Check if the component is still mounted before updating state
            if (isMounted) {
              setNotifications(notificationsData);
              if (notificationsData && typeof notificationsData[Symbol.iterator] === 'function') {
                const unreadNotifications = notificationsData.filter((notification) => !notification.isRead);
                setUnreadCount(unreadNotifications.length);
              }
            }
          } catch (error) {
              console.log(error);
            // Handle any errors here
          }
        }
      };

      
      fetchNotifications();
      const interval = setInterval(() => {
              fetchNotifications();
              setDisabledButtons([]);
      }, 10000); // 10 seconds in milliseconds
      // Clean up the interval on component unmount
      return () => {
          clearInterval(interval);
          isMounted = false;
      };
  }, []);
  
// const fetchNotifications = useCallback(async () => {
//     if(currentUser!==null && currentUser.userId!==null){
//         const notificationsData = await NotificationService.getAllNotifications(currentUser.userId);
//        //console.log(notificationsData);
//        setNotifications(notificationsData);
//        if(notificationsData){
//            const unreadNotifications = notificationsData.filter((notification) => !notification.isRead);
//            setUnreadCount(unreadNotifications.length);
//        }
//        
//     }
//  }, [setNotifications]);
  
  const handleNotificationClick = (e,notificationType) => {
    e.preventDefault();
    if(notificationType==='JOIN'){
        navigate.push("/missionview");
    }
  };

//userId, participantId, missionId, notificationType
  const handleNotificationHover = (participantId, missionId, isRead, notificationType) => {
      if(!isRead){
          NotificationService.markNotificationAsRead(currentUser.userId, participantId, missionId, notificationType);
      }
  };
  
  const handleJoinMission = async (e,missionId,index) => {
        e.preventDefault();
        e.stopPropagation();
        setDisabledButtons((prevDisabledButtons) => [...prevDisabledButtons, 'join-'+missionId+index, 'reject-'+missionId+index]);
        const msg=await MissionService.joinMission(currentUser.userId, missionId);
        if(msg!==''){
            alert(msg);
        }
  };
  const handleRejetToJoinMission = async (e,missionId,index) => {
        e.preventDefault();
        e.stopPropagation();
        setDisabledButtons((prevDisabledButtons) => [...prevDisabledButtons, 'join-'+missionId+index, 'reject-'+missionId+index]);
        const msg=await MissionService.rejectToJoinMission(currentUser.userId, missionId);
        if(msg!==''){
            alert(msg);
        }
  };
  
//  const toggleOffcanvas = () => {
//    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
//  };

//  const logOut = async (e) => {
//    // alert("alert")
//    e.preventDefault();
//    AuthService.logout(currentUser.userId, localStorage.getItem("radioId"));
//    localStorage.clear();
//    sessionStorage.clear();
//    navigate.replace("/login");
//  };

  const userName = () => {
    let firstName = "";
    let lastName = "";
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      firstName = user.firstName;
      lastName = user.lastName;
    }
    return firstName + " " + lastName;
  };

  const isAdmin = () => {
    let isAdmin = true;
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      isAdmin = user.admin;
    }
    return isAdmin;
  };
  
    return (

            <ul className="navbar-nav navbar-nav-right" style={{borderLeft: '2px solid white'}}>
                

                <li className="nav-item nav-profile" style={{paddingRight: "10px", paddingLeft:"5px"}}>
                    <Dropdown alignRight>
                        <Dropdown.Toggle variant="transparent" id="notification-dropdown-toggle">
                            <i className="mdi mdi-bell mdi-24px" style={{color:'white'}}></i>
                            {unreadCount > 0 && <span className="notification-dot"></span>}
                        </Dropdown.Toggle>
                        {Array.isArray(notifications)?
                        <Dropdown.Menu className="navbar-dropdown">
                            {notifications.map((data, index) => (
                                <Dropdown.Item href="#" className="notification-item" onClick={(e)=>{handleNotificationClick(e, data.notificationType);}} 
                                    onMouseEnter={() => {
                                        handleNotificationHover(data.userId, data.missionId, data.isRead, data.notificationType);}} key={`${data.missionId}${index}`}>
                                    <span className={`notification-text ${data.isRead ? '' : 'bold'}`}>
                                        {data.message}
                                        
                                        {data.notificationType==='JOIN'?
                                            <React.Fragment key={`${data.missionId}${index}`}>
                                                    <button type="button" className="btn btn-info btn-custom" 
                                                        disabled={isButtonDisabled(`join-${data.missionId}${index}`)}
                                                        onClick={(e)=>{
                                                            handleJoinMission(e, data.missionId, index);
                                                            }
                                                    }>

                                                        Join
                                                    </button>
                                                    <button type="button" className="btn btn-info btn-custom" 
                                                        disabled={isButtonDisabled(`reject-${data.missionId}${index}`)}
                                                        onClick={(e)=>{
                                                            handleRejetToJoinMission(e, data.missionId,index);
                                                            }
                                                    }>

                                                        Reject
                                                    </button>
                                            </React.Fragment>
                                    :""}
                                        
                                    </span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                        :""}
                    </Dropdown>
                </li>
                
                <li className="nav-item nav-profile" style={{paddingLeft:'5px'}}>
                    <Dropdown alignRight>
                        <Dropdown.Toggle>
                            <div className="nav-profile-text">
                                <div className="row">
                                    <div className="col-12" style={{textAlign:"start"}}>
                                        <span className="mb-1">{userName()}</span>
                                    </div>
                                    <div className="col-12 mt-1" style={{textAlign:"start"}}>
                                        <span className="text-small">
                                            {isAdmin() ? "Admin" : "User"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="navbar-dropdown">
                            <Dropdown.Item href="!#" onClick={(evt) => {
                                    evt.preventDefault();
                                    navigate.push("/profile");
                            }}>
                                <i className="mdi mdi-account-box mr-2 text-primary"></i>
                                Profile
                            </Dropdown.Item>

                            <Dropdown.Item href="!#" onClick={(evt) => {
                                    evt.preventDefault();
                                    navigate.push("/changepassword");
                            }}>
                                <i className="mdi mdi-key mr-2 text-primary"></i>
                                Change Password
                            </Dropdown.Item>
                            
                            <Dropdown.Item href="/logout">
                                <i className="mdi mdi-power mr-2 text-primary"></i>
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
                
            </ul>
            
  );
}

export default Navbar;
