import React, { Suspense, lazy,createRef } from 'react';
import {  Redirect, Route, Switch } from 'react-router-dom';


import Spinner from './components/Spinner';

const RadioDialog = lazy(() => import('./components/RadioDialog'));
const LogoutDialog = lazy(() => import('./components/LogoutDialog'));
const VideoStream = lazy(() => import('./views/VideoStream'));
const Dashboard = lazy(() => import('./views/Dashboard'));
const DashboardUser = lazy(() => import('./views/DashboardUser'));
const LandMarkForm = lazy(() => import('./views/landmark/LandMarkForm'));
const LandmarkView = lazy(() => import('./views/landmark/LandmarkView'));
const EditLandMark = lazy(() => import('./views/landmark/EditLandMark'));


const AddressAlertForm = lazy(() => import('./views/address-alert/AddressAlertForm'));
const EditAddressAlert = lazy(() => import('./views/address-alert/EditAddressAlert'));
const AddressAlertView = lazy(() => import('./views/address-alert/AddressAlertView'));

const ExtraPatrolAndTrafficInitForm = lazy(() => import('./views/extra-patrol-and-traffic-initiative/ExtraPatrolOrTrafficForm'));
const EditExtraPatrolAndTrafficInit = lazy(() => import('./views/extra-patrol-and-traffic-initiative/EditExtraPatrolOrTraffic'));
const ExtraPatrolAndTrafficInitView = lazy(() => import('./views/extra-patrol-and-traffic-initiative/ExtraPatrolOrTrafficView'));



const LandmarkTypeView = lazy(() => import('./views/landmark-type/LandmarkTypeView'));
const EditLandmarkTypeForm = lazy(() => import('./views/landmark-type/EditLandmarkTypeForm'));
const LandmarkTypeForm = lazy(() => import('./views/landmark-type/LandmarkTypeForm'));

const AddressAlertTypeForm = lazy(() => import('./views/address-alert-type/AddressAlertTypeForm'));
const EditAddressAlertTypeForm = lazy(() => import('./views/address-alert-type/EditAddressAlertTypeForm'));
const AddressAlertTypeView = lazy(() => import('./views/address-alert-type/AddressAlertTypeView'));

const EditMissionTypeForm = lazy(() => import('./views/mission-types/EditMissionTypeForm'));
const MissionTypeForm = lazy(() => import('./views/mission-types/MissionTypeForm'));
const MissionTypeView = lazy(() => import('./views/mission-types/MissionTypeView'));

const DroneMissionDashbord = lazy(() => import('./views/cloud-app/DroneMissionDashboard'));
const DroneVFAndControl = lazy(() => import('./views/cloud-app/DroneVFAndControlMap'));

const EditPOITypeForm = lazy(() => import('./views/poi-type/EditPOITypeForm'));
const POITypeForm = lazy(() => import('./views/poi-type/POITypeForm'));
const POITypeView = lazy(() => import('./views/poi-type/POITypeView'));

const EditRaceForm = lazy(() => import('./views/race/EditRaceForm'));
const RaceForm = lazy(() => import('./views/race/RaceForm'));
const RaceView = lazy(() => import('./views/race/RaceView'));

const poiProfileView = lazy(() => import('./views/poi/PoiProfileView'));
const poiProfileAndFirForm = lazy(() => import('./views/poi/PoiProfileForm'));
const poiProfileAndFirEditForm = lazy(() => import('./views/poi/EditPoiProfileForm'));
const PoISearch = lazy(() => import('./views/poi/PoiSearch'));
//const LiveSearchPoi = lazy(() => import('./views/poi/LiveSearch'));

const EditVehicleTypeForm = lazy(() => import('./views/vehicle-types/EditVehicleTypeForm'));
const VehicleTypeForm = lazy(() => import('./views/vehicle-types/VehicleTypeForm'));
const VehicleTypeView = lazy(() => import('./views/vehicle-types/VehicleTypeView'));

const EditVehicleModelForm = lazy(() => import('./views/vehicle-models/EditVehicleModelForm'));
const VehicleModelForm = lazy(() => import('./views/vehicle-models/VehicleModelForm'));
const VehicleModelView = lazy(() => import('./views/vehicle-models/VehicleModelView'));

const EditVehicleMakeForm = lazy(() => import('./views/vehicle-makes/EditVehicleMakeForm'));
const VehicleMakeForm = lazy(() => import('./views/vehicle-makes/VehicleMakeForm'));
const VehicleMakeView = lazy(() => import('./views/vehicle-makes/VehicleMakeView'));

const EditColorNameForm = lazy(() => import('./views/color-names/EditColorNameForm'));
const ColorNameForm = lazy(() => import('./views/color-names/ColorNameForm'));
const ColorNameView = lazy(() => import('./views/color-names/ColorNameView'));

const EditPartsOfBodyForm = lazy(() => import('./views/parts-of-body/EditPartsOfBodyForm'));
const PartsOfBodyForm = lazy(() => import('./views/parts-of-body/PartsOfBodyForm'));
const PartsOfBodyView = lazy(() => import('./views/parts-of-body/PartsOfBodyView'));

const EditGenderForm = lazy(() => import('./views/genders/EditGenderForm'));
const GenderForm = lazy(() => import('./views/genders/GenderForm'));
const GenderView = lazy(() => import('./views/genders/GenderView'));

const EditStateForm = lazy(() => import('./views/states/EditStateForm'));
const StateForm = lazy(() => import('./views/states/StateForm'));
const StateView = lazy(() => import('./views/states/StateView'));

const EditMission = lazy(() => import('./views/mission/EditMissionForm'));
const MissionView = lazy(() => import('./views/mission/MissionView'));
const AddMission = lazy(() => import('./views/mission/MissionForm'));
const inviteByRadioToMission = lazy(() => import('./views/mission/InviteByRadio'));
const MissionArchive = lazy(() => import('./views/mission/MissionArchive'));

const Profile = lazy(() => import('./views/profile/Profile'));

const Login = lazy(() => import('./views/auth/Login'));
const ChangePassword = lazy(() => import('./views/auth/ChangePassword'));
const ForgotPassword = lazy(() => import('./views/auth/ForgotPassword'));

const UserView = lazy(() => import('./views/users/UserView'));
const AgencyView = lazy(() => import('./views/agency/AgencyView'));
const RankView = lazy(() => import('./views/ranks/RankView'));
const DroneView = lazy(() => import('./views/drones/DroneView'));
const SpecialityUnitView = lazy(() => import('./views/speciality-units/SpecialityUnitView'));
const SpecialityUnitForm = lazy(() => import('./views/speciality-units/SpecialityUnitForm'));
const EditSpecialityUnitForm = lazy(() => import('./views/speciality-units/EditSpecialityUnitForm'));
const UserForm = lazy(() => import('./views/users/UserForm'));
const EditUserForm = lazy(() => import('./views/users/EditUserForm'));
const RankForm = lazy(() => import('./views/ranks/RankForm'));
const EditRankForm = lazy(() => import('./views/ranks/EditRankForm'));
const DroneForm = lazy(() => import('./views/drones/DroneForm'));
const DroneDetail = lazy(() => import('./views/drones/DroneDetail'));
const EditDroneForm = lazy(() => import('./views/drones/EditDroneForm'));
const AgencyForm = lazy(() => import('./views/agency/AgencyForm'));
const EditAgencyForm = lazy(() => import('./views/agency/EditAgencyForm'));


export const historyRef = createRef();

const isAuthenticated = () => {
  return !!localStorage.getItem('user');
};

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

function AppRoutes() {

  return (
    <Suspense fallback={<Spinner/>}>
      <Switch>
        <ProtectedRoute exact path="/dashboard" component={Dashboard }/>
        <ProtectedRoute exact path="/user" component={DashboardUser }/>
        <ProtectedRoute exact path="/addlandmark" component={LandMarkForm }/>
        <ProtectedRoute exact path="/videostream" component={VideoStream }/>

        <ProtectedRoute exact path="/landmarkview" component={LandmarkView }/>
        <ProtectedRoute exact path="/editLandmark" component={EditLandMark }/>

        <ProtectedRoute exact path="/addressalertview" component={AddressAlertView }/>
        <ProtectedRoute exact path="/addaddressalert" component={AddressAlertForm }/>
        <ProtectedRoute exact path="/editAddressAlert" component={EditAddressAlert }/>
        
        <ProtectedRoute exact path="/extrapatrolortrafficview" component={ExtraPatrolAndTrafficInitView }/>
        <ProtectedRoute exact path="/addextrapatrolortraffic" component={ExtraPatrolAndTrafficInitForm }/>
        <ProtectedRoute exact path="/editextrapatrolortraffic" component={EditExtraPatrolAndTrafficInit }/>
     
        <ProtectedRoute exact  path="/userview" component={UserView } />
        <ProtectedRoute exact  path="/agencyview" component={AgencyView } />
        <ProtectedRoute exact  path="/rankview" component={RankView } />
        <ProtectedRoute exact  path="/droneview" component={DroneView } />
        <ProtectedRoute exact  path="/specialityunitform" component={SpecialityUnitForm } />
        <ProtectedRoute exact  path="/editspecialityunitform" component={EditSpecialityUnitForm } />
        <ProtectedRoute exact  path="/userform" component={UserForm } />
        <ProtectedRoute exact  path="/edituserform" component={EditUserForm } />
        <ProtectedRoute exact  path="/rankform" component={RankForm } />
        <ProtectedRoute exact  path="/editrankform" component={EditRankForm } />
        <ProtectedRoute exact  path="/droneform" component={DroneForm } />
        <ProtectedRoute exact  path="/dronedetail" component={DroneDetail } />
        <ProtectedRoute exact  path="/editdroneform" component={EditDroneForm } />
        <ProtectedRoute exact  path="/agencyform" component={AgencyForm } />
        <ProtectedRoute exact  path="/editagencyform" component={EditAgencyForm } />
        <ProtectedRoute exact  path="/specialityunitview" component={SpecialityUnitView } />

        <ProtectedRoute exact  path="/editlandmarktypeform" component={EditLandmarkTypeForm } />
        <ProtectedRoute exact  path="/addlandmarktypeform" component={LandmarkTypeForm} />
        <ProtectedRoute exact  path="/landmarktypeview" component={LandmarkTypeView } />

        <ProtectedRoute exact  path="/editaddressalerttypeform" component={EditAddressAlertTypeForm } />
        <ProtectedRoute exact  path="/addaddressalerttypeform" component={AddressAlertTypeForm} />
        <ProtectedRoute exact  path="/addressalerttypeview" component={AddressAlertTypeView } />
       
        <ProtectedRoute exact  path="/editmissiontype" component={EditMissionTypeForm } />
        <ProtectedRoute exact  path="/addmissiontype" component={MissionTypeForm} />
        <ProtectedRoute exact  path="/missiontypeview" component={MissionTypeView } />
        
        <ProtectedRoute exact  path="/dronemissionDash" component={DroneMissionDashbord } />
        <ProtectedRoute exact  path="/droneVFAndControl" component={DroneVFAndControl } />
        
        <ProtectedRoute exact  path="/editpoitype" component={EditPOITypeForm } />
        <ProtectedRoute exact  path="/addpoitype" component={POITypeForm} />
        <ProtectedRoute exact  path="/poitypeview" component={POITypeView } />
        
        <ProtectedRoute exact  path="/editrace" component={EditRaceForm } />
        <ProtectedRoute exact  path="/addrace" component={RaceForm} />
        <ProtectedRoute exact  path="/raceview" component={RaceView } />
        
        <ProtectedRoute exact  path="/poiProfileView" component={poiProfileView } />
        <ProtectedRoute exact  path="/addpoiprofile" component={poiProfileAndFirForm } />
        <ProtectedRoute exact  path="/editpoiprofile" component={poiProfileAndFirEditForm } />
        <ProtectedRoute exact  path="/poisearch" component={PoISearch} />
        
        <ProtectedRoute exact  path="/editvehicletype" component={EditVehicleTypeForm } />
        <ProtectedRoute exact  path="/addvehicletype" component={VehicleTypeForm} />
        <ProtectedRoute exact  path="/vehicletypeview" component={VehicleTypeView } />

        <ProtectedRoute exact  path="/editvehiclemodel" component={EditVehicleModelForm } />
        <ProtectedRoute exact  path="/addvehiclemodel" component={VehicleModelForm} />
        <ProtectedRoute exact  path="/vehiclemodelview" component={VehicleModelView } />
        
        <ProtectedRoute exact  path="/editvehiclemake" component={EditVehicleMakeForm } />
        <ProtectedRoute exact  path="/addvehiclemake" component={VehicleMakeForm} />
        <ProtectedRoute exact  path="/vehiclemakeview" component={VehicleMakeView } />
        
        <ProtectedRoute exact  path="/editcolorname" component={EditColorNameForm } />
        <ProtectedRoute exact  path="/addcolorname" component={ColorNameForm} />
        <ProtectedRoute exact  path="/colornameview" component={ColorNameView } />

        <ProtectedRoute exact  path="/editgender" component={EditGenderForm } />
        <ProtectedRoute exact  path="/addgender" component={GenderForm} />
        <ProtectedRoute exact  path="/genderview" component={GenderView } />

        <ProtectedRoute exact  path="/editpartsofbody" component={EditPartsOfBodyForm } />
        <ProtectedRoute exact  path="/addpartsofbody" component={PartsOfBodyForm} />
        <ProtectedRoute exact  path="/partsofbodyview" component={PartsOfBodyView } />

        <ProtectedRoute exact  path="/editstate" component={EditStateForm } />
        <ProtectedRoute exact  path="/addstate" component={StateForm} />
        <ProtectedRoute exact  path="/stateview" component={StateView } />
                
        <ProtectedRoute exact  path="/editmission" component={EditMission } />
        <ProtectedRoute exact  path="/missionview" component={MissionView } />
        <ProtectedRoute exact  path="/addmission" component={AddMission } />
        <ProtectedRoute exact  path="/inviteByRadioToMission" component={inviteByRadioToMission } />
        <ProtectedRoute exact  path="/missionarchive" component={MissionArchive } />
        
        <ProtectedRoute exact  path="/radiodialog" component={RadioDialog } />
        <ProtectedRoute exact  path="/logout" component={LogoutDialog } />
        <ProtectedRoute  exact path="/profile" component={Profile } />
        <Route exact path="/login" component={Login } />
        <Route exact  path="/changepassword" component={ChangePassword } />
        <Route exact  path="/forgotpassword" component={ForgotPassword } />
         
        <Redirect to='/login'/>
      </Switch>
    </Suspense>
  );
}

export default AppRoutes
