//Geofence Alerts
//Location Alerts
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

class Sidebar extends Component {//side bar is moved to top   
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/dashboard", state: "ecommercePagesMenuOpen" }
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
  render() {
    let user = { firstName: "", lastName: "", admin: false };

    if (localStorage.getItem("user")) {
      user = JSON.parse(localStorage.getItem("user"));
    }

    // let firstName = user.firstName;
    // let lastName = user.lastName;
    // let admin = user.admin;

    return (
      
        <ul className="navbar-nav navbar-nav-left" id="sidebar">
          <li className={
              (
                user.admin
                  ? this.isPathActive("/dashboard")
                  : this.isPathActive("/user")
              )
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to={user.admin ? "/dashboard" : "/user"}>
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon" style={{color:"white"}}></i>
            </Link>
          </li>

          

          {user.admin ? (
            <React.Fragment >
                <li className="nav-item">
                        <Dropdown alignRight className="nav-link">
                            <Dropdown.Toggle variant="transparent">
                                <span className={(this.isPathActive("/userview") || this.isPathActive("/userform")|| this.isPathActive("/edituserform")
                        ||this.isPathActive("/agencyview") ||this.isPathActive("/agencyform")||this.isPathActive("/editagencyform")
                        ||this.isPathActive("/rankview") ||this.isPathActive("/rankform")||this.isPathActive("/editrankform")
                        ||this.isPathActive("/specialityunitview") ||this.isPathActive("/specialityunitform")||this.isPathActive("/editspecialityunitform")
                        ) ? "menu-title-active" : "menu-title" }>Account Management</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="navbar-dropdown">
                                <Dropdown.Item href="/userview">
                                    <i className="mdi mdi-account mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/userview") || this.isPathActive("/userform")|| this.isPathActive("/edituserform")) ? "menu-title-active" : "menu-title" }>User's Profile</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="/agencyview">
                                    <i className="mdi mdi-city mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/agencyview")||this.isPathActive("/agencyform")||this.isPathActive("/editagencyform")) ? "menu-title-active" : "menu-title" }>User's Agency</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="/rankview">
                                    <i className="mdi mdi-briefcase mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/rankview")||this.isPathActive("/rankform")||this.isPathActive("/editrankform")) ? "menu-title-active" : "menu-title" }>User's Ranks</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="/specialityunitview">
                                    <i className="mdi mdi-jeepney mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/specialityunitview")||this.isPathActive("/specialityunitform")||this.isPathActive("/editspecialityunitform")) ? "menu-title-active" : "menu-title" }>User's Speciality Unit</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                </li>
                
                <li className="nav-item">
                        <Dropdown alignRight className="nav-link">
                            <Dropdown.Toggle variant="transparent">
                                <span className={(this.isPathActive("/landmarktypeview") || this.isPathActive("/addlandmarktypeform")|| this.isPathActive("/editlandmarktypeform")
                        ||this.isPathActive("/addressalerttypeview") ||this.isPathActive("/addaddressalerttypeform")||this.isPathActive("/editaddressalerttypeform")
                        ||this.isPathActive("/missiontypeview") ||this.isPathActive("/addmissiontype")||this.isPathActive("/editmissiontype")
                        ) ? "menu-title-active" : "menu-title" }>Marker's Config</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="navbar-dropdown">
                                <Dropdown.Item href="/landmarktypeview">
                                    <i className="mdi mdi-map-marker-plus mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/landmarktypeview") || this.isPathActive("/addlandmarktypeform")|| this.isPathActive("/editlandmarktypeform")) ? "menu-title-active" : "menu-title" }>Landmark Type</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="/addressalerttypeview">
                                    <i className="mdi mdi-comment-alert mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/addressalerttypeview")||this.isPathActive("/addaddressalerttypeform")||this.isPathActive("/editaddressalerttypeform")) ? "menu-title-active" : "menu-title" }>Address Alert Type</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="/missiontypeview">
                                    <i className="mdi mdi-airplane mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/missiontypeview")||this.isPathActive("/addmissiontype")||this.isPathActive("/editmissiontype")) ? "menu-title-active" : "menu-title" }>Mission Type</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                </li>
                <li className="nav-item">
                        <Dropdown alignRight className="nav-link">
                            <Dropdown.Toggle variant="transparent">
                                <span className={(this.isPathActive("/vehicletypeview") || this.isPathActive("/addvehicletype")|| this.isPathActive("/editvehicletype")
                        ||this.isPathActive("/vehiclemakeview") ||this.isPathActive("/addvehiclemake")||this.isPathActive("/editvehiclemake")
                        ||this.isPathActive("/vehiclemodelview") ||this.isPathActive("/addvehiclemodel")||this.isPathActive("/editvehiclemodel")
                        ||this.isPathActive("/droneview") ||this.isPathActive("/droneform")||this.isPathActive("/editdroneform")
                        ) ? "menu-title-active" : "menu-title" }>Transport</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="navbar-dropdown">
                            
                            <Dropdown.Item href="/droneview">
                                    <i className="mdi mdi-quadcopter mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/droneview")||this.isPathActive("/droneform")||this.isPathActive("/editdroneform")) ? "menu-title-active" : "menu-title" }>Drones</span>
                                </Dropdown.Item>
                                
                                <Dropdown.Item href="/vehicletypeview">
                                    <i className="mdi mdi-car mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/vehicletypeview") || this.isPathActive("/addvehicletype")|| this.isPathActive("/editvehicletype")) ? "menu-title-active" : "menu-title" }>Vehicle Type</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="/vehiclemakeview">
                                    <i className="mdi mdi-car mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/vehiclemakeview")||this.isPathActive("/addvehiclemake")||this.isPathActive("/editvehiclemake")) ? "menu-title-active" : "menu-title" }>Vehicle Make</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="/vehiclemodelview">
                                    <i className="mdi mdi-car mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/vehiclemodelview")||this.isPathActive("/addvehiclemodel")||this.isPathActive("/editvehiclemodel")) ? "menu-title-active" : "menu-title" }>Vehicle Model</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                </li>
                
                <li className="nav-item">
                        <Dropdown alignRight className="nav-link">
                            <Dropdown.Toggle variant="transparent">
                                <span className={(this.isPathActive("/genderview")||this.isPathActive("/addgender")||this.isPathActive("/editgender")
                        ||this.isPathActive("/raceview") ||this.isPathActive("/addrace")||this.isPathActive("/editrace")
                        ||this.isPathActive("/poitypeview")||this.isPathActive("/addPOItype")||this.isPathActive("/editPOItype")
                        || this.isPathActive("/partsofbodyview") || this.isPathActive("/addpartsofbody")|| this.isPathActive("/editpartsofbody")
                        ) ? "menu-title-active" : "menu-title" }>Person</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="navbar-dropdown">
                            
                            <Dropdown.Item href="/genderview">
                                    <i className="mdi mdi-human-male-female mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/genderview")||this.isPathActive("/addgender")||this.isPathActive("/editgender")) ? "menu-title-active" : "menu-title" }>Gender</span>
                                </Dropdown.Item>
                                
                                <Dropdown.Item href="/raceview">
                                    <i className="mdi mdi-human-male-male mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/raceview") || this.isPathActive("/addrace")|| this.isPathActive("/editrace")) ? "menu-title-active" : "menu-title" }>Race</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="/partsofbodyview">
                                    <i className="mdi mdi-human-male-male mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/partsofbodyview") || this.isPathActive("/addpartsofbody")|| this.isPathActive("/editpartsofbody")) ? "menu-title-active" : "menu-title" }>Parts of Body</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="/poitypeview">
                                    <i className="mdi mdi-account-alert mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/poitypeview") || this.isPathActive("/addPOItype")|| this.isPathActive("/editPOItype")) ? "menu-title-active" : "menu-title" }>POI Type</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                </li>
                
              
              <li className="nav-item">
                  <Link className="nav-link" to="/colornameview">
                    <span className={(this.isPathActive("/colornameview")||this.isPathActive("/addcolorname")||this.isPathActive("/editcolorname")) ? "menu-title-active" : "menu-title" }>Color Names</span>
                  </Link>
                </li>
                
                <li className="nav-item">
                  <Link className="nav-link" to="/stateview">
                    <span className={(this.isPathActive("/stateview")||this.isPathActive("/addstate")||this.isPathActive("/editstate")) ? "menu-title-active" : "menu-title" }>Country States</span>
                  </Link>
                </li>
              
            </React.Fragment>
          ) : (
          <React.Fragment >
                <li className="nav-item">
                    <Dropdown alignRight={false} className="nav-link">
                        <Dropdown.Toggle variant="transparent">
                                <span className={
                                        (
                                            this.isPathActive("/landmarkview") || this.isPathActive("/addlandmark") || this.isPathActive("/editLandmark") ||
                                            this.isPathActive("/addressalertview") || this.isPathActive("/addaddressalert") || this.isPathActive("/editAddressAlert") ||
                                            this.isPathActive("/extrapatrolortrafficview") || this.isPathActive("/addextrapatrolortraffic")|| this.isPathActive("/editextrapatrolortraffic")
                                        )
                                        ? "menu-title-active" : "menu-title" }>
                                    Geofencing
                                </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="navbar-dropdown">
                            <Dropdown.Item href="/landmarkview">
                                <img src="/map-marker-radius-outline.svg" alt="" className="mr-2 menu-icon" width={17} height={17} />
                                <span className={(this.isPathActive("/landmarkview") || this.isPathActive("/addlandmark") || this.isPathActive("/editLandmark")) ? "menu-title-active" : "menu-title" }>Landmark</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="/addressalertview">
                                <i className="mdi mdi-map-marker-alert-outline mr-2 menu-icon"></i>
                                <span className={(this.isPathActive("/addressalertview") || this.isPathActive("/addaddressalert")|| this.isPathActive("/editAddressAlert")) ? "menu-title-active" : "menu-title" }>Address Alert</span>
                            </Dropdown.Item>
                            
                            <Dropdown.Item href="/extrapatrolortrafficview">
                                <img src="/car-emergency.png" alt="" className="mr-2 menu-icon" width={17} height={17} />
                                <i className="mdi mdi-car-emergency mr-2 menu-icon"></i>
                                <span className={(this.isPathActive("/extrapatrolortrafficview") || this.isPathActive("/addextrapatrolortraffic")|| this.isPathActive("/editextrapatrolortraffic")) ? "menu-title-active" : "menu-title" }>Extra Patrol/Traffic Initiative</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
                
                <li className="nav-item">
                    <Dropdown alignRight={false} className="nav-link">
                        <Dropdown.Toggle variant="transparent">
                                <span className={
                                        (
                                            (this.isPathActive("/missionview") || this.isPathActive("/addmission")  ||
                                             this.isPathActive("/editmission") || this.isPathActive("/inviteByRadioToMission")
                                            ) ||
                                            (this.isPathActive("/missionarchive"))
                                        )
                                        ? "menu-title-active" : "menu-title" }>
                                    Mission
                                </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="navbar-dropdown">
                            <Dropdown.Item href="/missionview">
                                <img src="/airplane-cog.svg" alt="" className="mr-2 menu-icon" width={17} height={17} />
                                <span className={(this.isPathActive("/missionview") || this.isPathActive("/addmission")  ||
                                             this.isPathActive("/editmission") || this.isPathActive("/inviteByRadioToMission")) ? "menu-title-active" : "menu-title" }>Manage Mission</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="/missionarchive">
                                <img src="/archive-search-outline.svg" alt="" className="mr-2 menu-icon" width={17} height={17} />
                                <span className={(this.isPathActive("/missionarchive")) ? "menu-title-active" : "menu-title" }>Archive Records</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
                <li className="nav-item">
                    <Dropdown alignRight={false} className="nav-link">
                        <Dropdown.Toggle variant="transparent">
                            <span className={(this.isPathActive("/poiProfileView") || this.isPathActive("/addpoiprofile") || this.isPathActive("/editpoiprofile") ||
                                    this.isPathActive("/poisearch")) ? "menu-title-active" : "menu-title" }>POI</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="navbar-dropdown">    
                            <Dropdown.Item href="/poiProfileView">
                                <i className="mdi mdi-human-male-female mr-2 menu-icon"></i>
                                <span className={(this.isPathActive("/poiProfileView") || this.isPathActive("/addPoiProfileAndFir") || this.isPathActive("/editPoiProfileAndFir")) ? "menu-title-active" : "menu-title" }>POI Profile/FIR</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="/poisearch">
                                <i className="mdi mdi-account-search mr-2 menu-icon"></i>
                                <span className={(this.isPathActive("/poisearch")) ? "menu-title-active" : "menu-title" }>Search POI</span>
                            </Dropdown.Item>
                            {/*
                                <Dropdown.Item href="#">
                                    <i className="mdi mdi-account-alert mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/liveSearchPoi")) ? "menu-title-active" : "menu-title" }>Live Search</span>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <i className="mdi mdi-account-alert mr-2 menu-icon"></i>
                                    <span className={(this.isPathActive("/liveTrackPoi")) ? "menu-title-active" : "menu-title" }>Live Track</span>
                                </Dropdown.Item>
                                */}
                            </Dropdown.Menu>
                        </Dropdown>
                </li>
          
          </React.Fragment>
          )}


        </ul>
        
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Sidebar);
