import React, { Component} from "react";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
// import Footer from "./components/Footer";
import AuthService from "./services/auth-service";
import { withRouter } from "react-router-dom";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            currentUser: undefined,
        };
    }

    componentDidMount() {
        this.onRouteChanged();
    }

    render() {

        let navbarComponent = !this.state.isFullPageLayout;
        let sidebarComponent = !this.state.isFullPageLayout;
        let footerComponent = !this.state.isFullPageLayout;

        return (
                <PageRender
                    currentUser={this.state.currentUser}
                    navbarComponent={navbarComponent}
                    sidebarComponent={sidebarComponent}
                    footerComponent={footerComponent}
                    isFullPageLayout={this.state.isFullPageLayout}
                    pathname={this.props.location.pathname}
                    />
                );
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({currentUser: user});
            this.setState({isAdmin: user.admin});
        }

        const body = document.querySelector("body");
        if (this.props.location.pathname === "/layout/RtlLayout") {
            body.classList.add("rtl");
        } else {
            body.classList.remove("rtl");
        }
        window.scrollTo(0, 0);
        const fullPageLayoutRoutes = [
            "/login",
            "/forgotpassword",
            "/radiodialog",
            "/logout"
        ];
        for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
            if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
                this.setState({
                    isFullPageLayout: true,
                });
                document
                        .querySelector(".page-body-wrapper")
                        .classList.add("full-page-wrapper");
                break;
            } else {
                this.setState({
                    isFullPageLayout: false,
                });
                document
                        .querySelector(".page-body-wrapper")
                        .classList.remove("full-page-wrapper");
            }
        }
    }
}

const PageRender = (props) => {
    let navbarComponent = props.navbarComponent ? <Navbar /> : "";
    let sidebarComponent = props.sidebarComponent ? <Sidebar /> : "";
    // let footerComponent = props.footerComponent ? <Footer /> : "";
    let pathname = props.pathname;
    return (
            <div className="container-scroller" style={{overflow:"scroll"/*Fix the date display problem when calendar crosses the boundry of pages and the page is not scrolling to show the calendar fully.*/}}>
                {!props.isFullPageLayout ?
                            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row navbar-dark bg-dark ">
                                <div className="navbar-menu-wrapper d-flex align-items-stretch">
                                    {sidebarComponent}
                                    {navbarComponent}
                                </div>
                            </nav>
                            : ""}
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className={(pathname === "/user" || pathname === "/dronemissionDash" || pathname === "/droneVFAndControl") ? "" : "content-wrapper"}>
                            <AppRoutes />
                        </div>
                    </div>
                </div>
            </div>
            );
};

export default withRouter(App);
